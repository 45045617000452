/*========================================================================
  Custom css to override the DevX default styles
======================================================================= */
:root {
    --base-accent: #64a021;
}

.dx-popup-wrapper>.dx-state-focused.dx-popup-fullscreen,
.dx-popup-wrapper>.dx-popup-fullscreen {
    border: none;
}

.dx-popup-content .dx-form-group-with-caption>.dx-form-group-content {
    padding-top: 5px !important;
}

.d-con-popup-content .dx-form-group-caption {
    font-size: 18px !important;
}

.d-con-popup-content .dx-texteditor-with-floating-label.dx-editor-outlined .dx-tag-container.dx-texteditor-input-container {
    padding-top: 0 !important;
}

.dx-formdialog .dx-popup-content {
    padding: 20px;
}

.dx-popup-fullscreen .dx-popup-content {
    background-color: #f4f5f8;
    padding: 0 !important;
}

.dx-popup-fullscreen .dx-popup-bottom.dx-toolbar {
    padding: 8px 15px;
    background: #393a3d !important;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
    width: 110px;
    min-width: 110px;
}

.dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit {
    width: 100px;
    min-width: 100px;
}

.dx-form label.dx-field-item-label {
    font-weight: normal;
}


.dx-numberbox .dx-texteditor-input {
    text-align: right;
}

.dx-button-danger.dx-button-mode-contained {
    background-color: #ffffff !important;
    border-color: #8d9096 !important;
    color: #454547 !important;
}

.dx-button-danger.dx-button-mode-contained .dx-icon {
    color: #fff;
}

.dx-button-danger.dx-button-mode-contained.dx-state-hover {
    border-color: #6e7178 !important;
}

.dx-button-danger.dx-button-mode-contained.dx-state-focused {
    background-color: #e6e6e6 !important;
    border-color: #4e5155 !important;
}

.dx-button-danger.dx-button-mode-contained.dx-state-active {
    background-color: #bfbfbf !important;
    border-color: #4e5155 !important;
    color: #393a3d !important;
}

.d-dialog-button.dx-button {
    font-weight: 600;
}

.d-dialog-button.dx-button.dx-button-danger.dx-state-hover {
    background-color: white;
    box-shadow: inset 0 0 0 1px #8d9096;
}

.d-dialog-button.dx-button.dx-button-danger.dx-state-focused {
    background-color: white !important;
    box-shadow: inset 0 0 0 1px transparent, 0 0 0 3px #dcdcdc;
}

.d-trowser-button.dx-button {
    font-weight: 600;
}

.d-trowser-button.dx-button.dx-button-success {
    box-shadow: none;
    border: 1px solid #434549;
    background-color: #1a1b1d;
    min-width: 80px;
}

.dx-popup-fullscreen .dx-popup-cancel {
    background-color: #2a2b2d !important;
    border-color: #2a2b2d !important;
    color: #fff !important;
}

.dx-popup-fullscreen .dx-popup-cancel:hover {
    background-color: #2a2b2d !important;
    border-color: #2a2b2d !important;
}

.dx-popup-fullscreen .dx-popup-cancel .dx-state-focused {
    background-color: #1a1b1d !important;
    border-color: #1a1b1d !important;
}


.d-trowser-button.dx-button.dx-button-default {
    box-shadow: none;
    border: 1px solid #64a021;
    background-color: #64a021;
    min-width: 80px;
}

.d-filter-button.dx-button.dx-button-default {
    box-shadow: none;
    border: 1px solid #64a021;
    background-color: #64a021;
    min-width: 80px;
}

.d-filter-button.dx-button.dx-button-success {
    box-shadow: none;
    border: 1px solid #434549;
    background-color: #1a1b1d;
    min-width: 80px;
}

.dx-popup-fullscreen .dx-popup-done {
    background-color: #82ad38 !important;
    border-color: #82ad38 !important;
    color: #fff !important;
}

.dx-popup-fullscreen .dx-validationsummary {
    display: none !important
}

.dx-popup-fullscreen .dx-popup-done .dx-state-hover {
    background-color: #82ad38 !important;
    border-color: #82ad38 !important;
}

.dx-popup-fullscreen .dx-popup-done .dx-state-focused {
    background-color: #5d941e !important;
    border-color: #5d941e !important;
}

.dx-popup-title .dx-button,
.dx-popup-bottom .dx-button {
    border-radius: 6px;
}

.dx-button-danger.dx-button-mode-contained {
    background-color: #ffffff !important;
    border-color: #8D9096 !important;
    color: #454547 !important;
}

.dx-button-danger.dx-button-mode-contained .dx-icon {
    color: #fff;
}

.dx-button-danger.dx-button-mode-contained.dx-state-hover {
    border-color: #6e7178 !important;
}

.dx-button-danger.dx-button-mode-contained.dx-state-focused {
    background-color: #e6e6e6 !important;
    border-color: #4e5155 !important;
}

.dx-button-danger.dx-button-mode-contained.dx-state-active {
    background-color: #bfbfbf !important;
    border-color: #4e5155 !important;
    color: #393A3D !important;
}

.dx-popup-wrapper>.dx-popup-fullscreen,
.dx-popup-wrapper>.dx-state-focused.dx-popup-fullscreen {
    border: none !important;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.md-con-form {
    padding: 10px 15px;
}

.md-con-popup-form {
    padding: 15px 0;
}

.dx-toolbar-after .dx-toolbar-item,
.dx-toolbar-after .dx-toolbar-item:last-child {
    padding: 0 0 0 5px !important;
}

.md-con-form .dx-texteditor-with-floating-label.dx-editor-outlined .dx-tag-container.dx-texteditor-input-container {
    padding-top: 0 !important;
}


.md-content-popup-block-card {
    padding: 15px;
    background-color: #fff;
    height: calc(100vh - 115px);
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
}


#patient-data-treeview .dx-treeview-item-content {
    border-radius: 3px !important;
}


.md-trash-button {
    font-size: 18px;
    cursor: pointer !important;
}

.mdl-10 {
    margin-left: 10px !important;
}

.md-row-header-template {
    border-radius: 4px !important;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
}

.md-row-header-template b {
    font-weight: 700 !important;
}

.md-row-body-template {
    border-radius: 3px !important;
    padding: 6px 10px !important;
    font-weight: normal !important;
}

.md-row-header-template li {
    padding: 0 5px !important;
}

.md-row-header-template h6 {
    color: rgb(51, 122, 183) !important;
    font-weight: 600 !important;
}

h5 {
    margin-top: 10px !important;
}

table td {
    border-color: #ddd !important;
}

.md-row-body-template p {
    font-family: "Poppins", sans-serif !important;
}

.md-row-body-template table {
    border: 0 !important;
}

table {
    width: 100% !important;
}

.md-row-body-template ul,
.md-row-body-template ol,
.md-row-body-template p {
    margin-bottom: 0 !important;
    font-weight: normal !important;
    font-size: 15px !important;
}

.md-row-body-template ul,
.md-row-body-template ol {
    padding-left: 1rem !important;
}

.md-head-title {
    font-size: 24px !important;
    clear: both;
}

.md-appt-tb td {
    padding: 0 !important;
    text-align: left !important;
}

table td {
    text-align: left !important;
    border-color: #ddd !important;
    padding: 4px 8px;
}

.md-row-body-template td,
.md-row-body-template strong {
    font-weight: normal !important;
}

.dx-command-select {
    text-align: center !important;
}

.md-action-btn {
    font-size: 20px;
    cursor: pointer;
    margin-left: 4px;
}

.md-grid-no-batch .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
    width: 30px !important;
}

.dx-header-row {
    background-color: #f2f2f2 !important;
    color: #222 !important;
    font-weight: 500 !important;
}

.d-content-grd-block-card {
    height: calc(100vh - 175px);
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    overflow-y: auto;
}

.action-img,
.md-icon-btn-left {
    cursor: pointer;
}

.md-ml-1 {
    margin-left: 5px !important;
}

.md-ml-15 {
    margin-left: 15px !important;
}

.text-center {
    text-align: center !important;
}

.Gtx-Trans-Icon,
#Gtx-Trans {
    display: none !important;
}

.md-action-col {
    display: flex;
    justify-content: center;
}

.appt-type th {
    font-weight: 500 !important;
}

.btn-outline-icon {
    padding: 0.35px;
    font-size: 14px;
    border-radius: 3px;
    border: 1px solid #6c757d;
}


.custom-primary {
    color: #007bff;
}

.custom-danger {
    color: #dc3545;
}

.md-mt-5 {
    margin-top: 5px !important;
}

.container-div {
    overflow: hidden;
}


.company-info {
    line-height: 1.1em;
    font-size: 0.8em;
    font-weight: bold;
    float: right;
    color: #333;
    margin-bottom: 5px;
}

.company-info div {
    margin-top: 0.5em;
}

.md-con-padding {
    padding: 15px !important;
}

.card-body-grd {
    padding: 0 !important;
}


.card-header {
    background-color: #fff !important;
}

.custom-treeview .dx-treeview-item {
    font-size: 15px;
    padding: 8px 12px;
    color: #333;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.custom-treeview .dx-treeview-node {
    margin-bottom: 5px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding-left: 0 !important;
}


.custom-treeview .dx-treeview-node:hover {
    background-color: #e9ecef !important;
    color: #000 !important;
}

.custom-treeview .dx-treeview-node .dx-treeview-node {
    margin-left: 20px;
    border-left: 2px solid #e0e0e0;
    padding-left: 10px;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-10 {
    margin-top: 10px !important;
}



.report-container {
    font-family: "Poppins", sans-serif !important;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 5px;
}

.header-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.company-info {
    color: #444;
    text-align: right;
}

.company-info p {
    margin: 0;
    line-height: 1.5;
    font-size: 14px !important;
}

.appointment-info {
    padding-top: 10px;
}



/* Patient Chart */

.action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.action-buttons .btn {
    flex: 1;
    margin: 0 5px;
    padding: 5px;
    text-align: center;
    border-radius: 4px;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
}

.btn-secondary {
    background-color: #6c757d;
    color: #fff;
}

.btn-danger {
    background-color: #dc3545;
    color: #fff;
}


.action-icon {
    width: 20px;
    height: 20px;
}

.admission-info-section {
    margin-top: 10px;
}

.toggle-section {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: #f8f9fa;
    align-items: center;
}


.admission-info .info-item {
    padding: 8px;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
}

.filters-section {
    margin-top: 10px;
}

.filters-section .dx-field {
    margin-bottom: 15px;
}

.filters-section .col-md-6,
.filters-section .col-md-12 {
    padding-right: 15px;
    padding-left: 15px;
}

.filters-section .col-md-12 {
    margin-top: 10px;
}

.switch-and-categories {
    margin-top: 10px;
}

.switch-and-categories .dx-field {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.switch-and-categories .dx-field-label {
    width: 100px;
    text-align: right;
    padding-right: 10px;
}

.switch-and-categories .category-status {
    display: flex;
    flex-wrap: wrap;
}

.switch-and-categories .category-item {
    display: flex;
    align-items: center;
    margin: 5px;
    font-size: 14px;
}

.switch-and-categories .category-item .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.widget-link {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    background-color: #f8f9fa;
    text-align: left;
    font-size: 14px;
}

.widget-link:hover {
    background-color: #e9ecef;
}

.dx-tree-view {
    margin-top: 20px;
}

.md-icon-btn {
    cursor: pointer;
    margin-left: 10px;
}

.selected {
    font-weight: 600 !important;
}

.bx-icon {
    font-size: 22px !important;
    vertical-align: bottom;
}

.view-buttons {
    display: flex;
    justify-content: center;
}

.btn-view {
    flex: 1;
    border: 1px solid #ccc;
    background-color: #f8f9fa;
    color: #333;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    margin: 0 5px;
}

.btn-view.active {
    background-color: #82ad38;
    color: #fff;
    border-color: #82ad38;
}

.btn-view:hover {
    border-color: #82ad38;
}



.tree-node-icons img {
    max-width: 15px !important;
    width: 100%;
    max-height: 15px !important;
    height: 100%;
}

.tree-node-item {
    padding: 6px 5px !important;
}



.selected-item {
    background-color: #e9ecef;
    color: #000;
    border-radius: 3px;
}

.md-scheduler .dx-popup-content {
    max-height: 100% !important;
    height: 100% !important;
}

.btn-outline-secondary {
    border-color: #6d9230 !important;
    color: #82ad38 !important;
    background-color: transparent !important;
}

.btn-outline-secondary:hover {
    border-color: #82ad38 !important;
    color: #fff !important;
    background-color: #82ad38 !important;
}

.btn-outline-secondary:focus,
.btn-outline-secondary:active {
    box-shadow: 0 0 0 0.2rem rgba(130, 173, 56, 0.25) !important;
}

.dp-none {
    display: none !important;
}


.patient-chart-header {
    overflow-x: auto;
    white-space: nowrap;
}

.patient-chart-header .d-flex {
    flex-wrap: nowrap;
}