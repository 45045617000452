
div#detailed-template-preview
    label[metadata]
        cursor: pointer
        border: 1px solid red
        padding: 2px
.f-11
    font-size: 11px

.badge-outline
    color: green
    border: 1px solid green
    padding: 1px 5px
    border-radius: 3px

a.active-link
    color: white

.table-striped tr th
    font-size: 14px

.table-striped tr td
    font-size: 14px

.preview-template-table
    width: 100%
    border-collapse: collapse
    border: 1px solid
    tr
        border: 1px solid
        th, td
            border: 1px solid

.dx-list .dx-scrollable-container
    overflow-y: auto

    .dx-scrollbar-vertical.dx-scrollbar-hoverable
        display: none

.dx-template-wrapper
    .report-node-container
        height: 100% !important

.fit-content-width
    width: fit-content

.max-content-min-width
    min-width: max-content

.gap-px-2
    gap: 2px

.truncate-text
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

  
